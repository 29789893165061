import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';
import OrderList from './ProfileTabs.OrderList';

const TabTypes = {
  Profile: 'Profile',
  OrderList: 'OrderList',
};

class ProfileTabs extends React.Component {
  state = {
    tab_type: TabTypes.Profile,
  };

  render() {
    let { extraCss } = this.props;
    let { tab_type } = this.state;

    return (
      <Wrapper extraCss={extraCss}>
        <Widget.Row
          justify="center"
          extraCss="border-bottom: 1px dotted black;"
        >
          <Widget.FlatButton
            label="基本資料"
            labelStyle={{ color: tab_type === TabTypes.Profile ? '' : '#ddd' }}
            onClick={() => this.setState({ tab_type: TabTypes.Profile })}
          />
          <Widget.FlatButton
            label="訂單記錄"
            labelStyle={{
              color: tab_type === TabTypes.OrderList ? '' : '#ddd',
            }}
            onClick={() => this.setState({ tab_type: TabTypes.OrderList })}
          />
        </Widget.Row>
        <div>
          {tab_type === TabTypes.Profile && <div>Profile</div>}
          {tab_type === TabTypes.OrderList && <OrderList />}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${props => props.extraCss || ''};
`;

export default ProfileTabs;
