import React, { Component } from 'react';
import styled from 'styled-components';
import SocialLogin from 'react-social-login';
import Constants from '../Domain/constants';
import * as L from '../Utils/Lang';

const FacebookLogo =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhBwoKADWXdR8GAAAB7UlEQVRo3u2ZPUtcQRSGnzNYCKm8hQQLKyGkEhHZLcLWFmmSVNsFUmojFhbp/QE2FvkF619IsLDaZSFfkI8iRVYESZEriItI0GOxitndSbx3ZrxjMW85d957njNzZ+YyR/hLmtGgTo0lHhBefbp0aLMnufW5NjXXKpRrczx4pq1Kgl+rpdkgslwN/Qdm72DQ/6d9FiQHA8B25eFhlu2buY+lJohm/GCq8vwHOmLO0IgWHqZoGOrRwgPUDbWoADXRkzvZ9YqqL6qBX/mHd3xln+OR9ie8svYPuqxOdUOn7Vz60m6ZCJh7j+fyvqzJBAv/lsXy4cMBvGFZfrsYwwAcsCYXbtYwAOvSd7WGANiVHXezP8A5qz52f4Av8i0uwCc/u/9G9Hm8SWfY4mmxVP0BbCPwmhdF7f6H0UP5NZK/cMBMZQAioy06yWlxf7izwFEJoPwqeMb3W3qc8djq2wwD8FNuARC1IeqhvXf0KUgACSABRAcovxE9Gj4+5eNoBxXmLb5/XAKl4zgBJIAEkAASwD0AcL7dCaK+oRsVoGvoRAXoGNpRAdreJRuvH5Ij5ozkrETLf+WqhupTtBx/q04WtLZuLJn2KgfoDWqnBkByFvC4bnXQzqBuOkzuVLx2GIGh4vXQN+xSvi+xCqzl+0tKfT0KspeCNQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxNy0wNy0xMFQxMDowMDo1MyswMjowMJw2HIMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTctMDctMTBUMTA6MDA6NTMrMDI6MDDta6Q/AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==';

class Button extends Component {
  render() {
    let { children, triggerLogin, ...props } = this.props;

    if (children) {
      return children(triggerLogin, ...props);
    }

    return (
      <Button.Wrapper onClick={triggerLogin} {...props}>
        <img src={FacebookLogo} alt="facebook-logo"/>
        <span>{L.s('log-in-with-facebook')}</span>
      </Button.Wrapper>
    );
  }

  static Wrapper = styled.div`
    width: 256px;
    max-width: 100%;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #4267b2;
    cursor: pointer;

    & > span {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
      color: white;
    }

    & > img {
      width: 24px;
      height: 24px;
    }

    ${props => props.extraCss || ''};
  `;
}

Button = SocialLogin(Button);

class FacebookLoginButton extends Component {
  render() {
    let { children, extraCss, onLogin } = this.props;

    return (
      <Button
        extraCss={extraCss}
        provider="facebook"
        appId={Constants.facebookAppId}
        onLoginSuccess={user => {
          console.log('user', user);
          this.node.props.triggerLogout();
          onLogin(user._token.accessToken);
        }}
        onLogoutSuccess={() => console.log('Log out Facebook...')}
        getInstance={node => {
          if (node) {
            this.node = node;
          }
        }}
      >
        {children}
      </Button>
    );
  }
}

export default FacebookLoginButton;
