import React from 'react';
import { connect } from 'react-redux';
import { withPage } from '../Page';
import * as Widget from '../Components/Widget';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import Login from '../Components/Login';
import ProfileTabs from '../Components/ProfileTabs';

class ProfilePage extends React.Component {
  render() {
    let { appActions, profile } = this.props;

    return (
      <Widget.MaxWidth width={800} extraCss="padding: 20px">
        {profile ? (
          <div>
            <Widget.Center extraCss="margin-bottom: 20px;">
              <h1>Login as {profile.user.username}</h1>
              <Widget.Button
                style={{ marginTop: 10 }}
                label="logout"
                onClick={() => appActions.logout()}
              />
            </Widget.Center>

            <ProfileTabs />
          </div>
        ) : (
          <Widget.Center>
            <Login.Button />
          </Widget.Center>
        )}
      </Widget.MaxWidth>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(ProfilePage)
);
